import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { JwtTokenService } from '../_shared/services/jwt-token.service';
import { AuthActions } from './action-types';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Action, Store} from '@ngrx/store';
import { of } from 'rxjs';
import { ToastService } from '../_shared/services/toast.service';
import { FacebookService } from '../_shared/services/facebook.service';
import { NotificationService } from '../_shared/services/notification.service';
import { ChatService } from '../_shared/services/chat.service';
import { AppState } from '../reducers';
import { Md5 } from 'ts-md5';
import {CookieService} from 'ngx-cookie-service';


@Injectable()
export class AuthEffects implements OnInitEffects {

  init$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.authInit),
      mergeMap(() => {
        return this.authService.isLoggedIn()
          .pipe(
            map(response => {
              return AuthActions.loginSuccess({user: response});
            }),
            catchError((err) => {
              return of(AuthActions.loginFailure({error: err.error}));
            })
          );
      })
    )
  );

  login$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.login),
      mergeMap((action) => this.authService.login({
                                                    verifier: action.user.verifier,
                                                    password: action.user.password
                                                  })
        .pipe(
          map(response => {
            this.writeDataInLocalStorage(response);
            return AuthActions.getCurrentUser();
          }),
          catchError( (error) => {
            return of(AuthActions.loginFailure({error: error.error}));
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.loginSuccess),
      mergeMap(() =>  {
        return of(AuthActions.getNotifications());
      })
    )
  );

  getNotifications$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.getNotifications),
      mergeMap(() => {
        /*this.notificationService.startConnection();*/
        this.notificationService.addNotificationListener();
        return this.chatService.unreads()
          .pipe(
            map(response => {
              return AuthActions.getNotificationsSuccess({chatIds: response});
            }),
            catchError((err) => {
              return of(AuthActions.getNotificationsFailure({error: err.error}));
            })
          );
      })
    )
  );

  loginFacebook$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.loginFacebook),
      mergeMap((action) => this.authService.loginWithFacebook(action.accessToken)
          .pipe(
            map(response => {
              this.writeDataInLocalStorage(response);
              return AuthActions.getCurrentUser();
            }),
            catchError( (error) => {
              return of(AuthActions.loginFailure({error: error.error}));
            })
          )
      )
    )
  );

  getCurrentUser$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.getCurrentUser),
      mergeMap(() => this.authService.isLoggedIn()
        .pipe(
          map(response => {

            if (this.route.snapshot.queryParamMap.has('navigate_to')){
              this.router.navigate([`${this.route.snapshot.queryParamMap.get('navigate_to')}`], { replaceUrl: true });
            } else if (response.verificationType === 'email' && !response.userAccountConfirmed){
              this.router.navigate(['/verifikacija-emaila'], { queryParams: { email: response.userName }, replaceUrl: true });
            } else {
              this.router.navigate(['/main'], {replaceUrl: true});
            }
            return AuthActions.loginSuccess({user: response});
          }),
          catchError((err) => {
            this.showErrorToast(err.error);
            return of(AuthActions.loginFailure({error: err.error}));
          })
        )
      )
    )
  );

  register$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.register),
      mergeMap((action) => this.authService.register( action.user, action.isLegal )
        .pipe(
          map(response => {

              return AuthActions.login({ user: {
                                                        verifier: action.user.verifier,
                                                        password: action.user.password
                                                      }});
          }),
          catchError((err) => {
            return of(AuthActions.registerFailure({error: err.error}));
          })
        )
      )
    )
  );

  logout$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.logout),
      mergeMap((action) => this.authService.logout()
        .pipe(
          map(response => {

            if (action.user.verificationType === 'facebook') {
              this.facebookService.logout();
            }

            localStorage.removeItem('role');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expiration');

            this.cookieService.deleteAll();

            this.notificationService.disconnect();
            // this.router.navigate(['/']);
            return AuthActions.logoutSuccess();
          }),
          catchError((err) => {
            this.router.navigate(['/']);
            return of(AuthActions.logoutSuccess());
          })
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private facebookService: FacebookService,
              private toastController: ToastController,
              private toastService: ToastService,
              private jwtTokenService: JwtTokenService,
              private notificationService: NotificationService,
              private chatService: ChatService,
              private cookieService: CookieService,
              private store: Store<AppState>,
              ) {
  }

  ngrxOnInitEffects(): Action {

    const userFingerprint = localStorage.getItem('fingerprint');

    const theme = localStorage.getItem('theme');

    if (!theme || theme.length === 0){
      localStorage.setItem('theme', 'light');
    }

    if ( !userFingerprint || userFingerprint.length === 0 ) {
      this.createUserFingerprint();
    }
    return AuthActions.authInit();
  }

  async showErrorToast(err) {

    const toast = await this.toastController.create({
                                                      message:       err.message,
                                                      duration:      2000,
                                                      position:      'top',
                                                      color:         'danger',
                                                      keyboardClose: true,
                                                      animated:      true
                                                    });
    await toast.present();
  }

  createUserFingerprint(): void {

    const date = new Date();
    const md5 = new Md5();
    const hash = md5.appendStr(date.getTime().toString()).end();

    localStorage.setItem('fingerprint', hash.toString());
  }

  writeDataInLocalStorage( response ): void {
    localStorage.setItem('role', this.jwtTokenService.getRoleFromToken(response.accessToken));
    localStorage.setItem('accessToken', response.accessToken);
    localStorage.setItem('refreshToken', response.refreshToken);
    localStorage.setItem('expiration', this.jwtTokenService.getExpirationFromToken(response.accessToken));

    // this.cookieService.set('role', this.jwtTokenService.getRoleFromToken(response.accessToken));
    this.cookieService.set('accessToken', response.accessToken);
    // this.cookieService.set('refreshToken', response.refreshToken);
    // this.cookieService.set('expiration', this.jwtTokenService.getExpirationFromToken(response.accessToken));
  }
}


