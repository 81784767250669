import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-img-container',
  templateUrl: './custom-img-container.component.html',
  styleUrls: ['./custom-img-container.component.scss'],
})
export class CustomImgContainerComponent implements OnInit {
  @Input() images: { image: string; thumbImage: string; alt: string; title: string }[] = [];
  constructor() { }

  ngOnInit() {
  }
}
