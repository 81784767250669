import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { User } from '../_shared/models/User';
import { PlatformService } from '../_shared/services/platform.service';
import { isLoggedIn } from '../auth/auth.selectors';
import { AppState } from '../reducers';

@Component({
             selector:    'app-home',
             templateUrl: 'home.page.html',
             styleUrls:   ['home.page.scss'],
           })
export class HomePage implements OnInit {

  route     = '/';
  postRoute = '/prijava';
  authUser: User;

  loaded = false;

  constructor(public platformService: PlatformService,
              private router: Router,
              private store: Store<AppState>) {

    this.subscribeOnRouteChanges();
  }

  ionViewWillEnter() {
    this.route = this.router.url;
    this.loaded = true;
  }


  ngOnInit() {

    this.checkForLoginUser();
  }

  subscribeOnRouteChanges() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.route = event.url;
      }
    });
  }

  checkForLoginUser(): void {

    this.store
      .pipe(select(isLoggedIn))
      .subscribe(response => {
        if (response) {
          this.postRoute = '/home/oglasi/dodaj-oglas';
        } else {
          this.postRoute = 'prijava?navigate_to=%2Fhome%2Foglasi%2Fdodaj-oglas';
        }
      });
  }

  navigateHome() {
    this.route = '/';
    this.router.navigateByUrl('/restorani');
  }

  navigateAddNewClassfield(): void {
    this.router.navigateByUrl(this.postRoute);
  }
}
